<template>
  <main class="stages-wrapper">
    <div
      class="filter-all"
      :class="[!filterAll ? 'stage' : 'stage-selected']"
      @click="selectAllStages()"
    >
      <p class="-ntb" :class="{ 'not-selected': !filterAll }">
        {{ filterAllText }}
      </p>
    </div>

    <div
      v-for="stage in stages"
      :key="stage"
      :class="[!stage.selected ? 'stage' : 'stage-selected']"
      @click="setSelectedStages(stage)"
      :title="stage.stage_name"
    >
      <p
        :style="`color: ${stage.color_code}`"
        class="-ntb"
        :class="{ 'not-selected': !stage.selected }"
      >
        {{ stage.stage_value }}
      </p>
    </div>
  </main>
</template>

<script>
export default {
  emits: ["getPickedStages"],
  data() {
    return {
      filterAll: false,
      filterAllText: "All",
      selectedStages: [],
      stages: null,
    };
  },
  created() {
    //sets this.stages and selects them as the selectedStages on the store
    this.stages = JSON.parse(JSON.stringify(this.$store.state.stages));

    this.stages.forEach((stage) => {
      if (this.$store.state.selectedStages.includes(stage.frame_stage_id)) {
        this.setSelectedStages(stage);
      }
    });

    console.log(this.selectedStages);
  },
  methods: {
    setSelectedStages(stage) {
      !this.selectedStages.includes(stage.frame_stage_id)
        ? this.selectedStages.push(stage.frame_stage_id)
        : this.selectedStages.splice(
            this.selectedStages.indexOf(stage.frame_stage_id),
            1
          );

      stage.selected = !stage.selected;

      console.log(this.selectedStages);
    },
    selectAllStages() {
      if (!this.filterAll) {
        this.filterAll = !this.filterAll;
        this.selectedStages = [];
        this.stages.forEach((stage) => {
          this.selectedStages.push(stage.frame_stage_id);
          stage.selected = this.filterAll;
        });
        this.filterAllText = "Clear";
        console.log(this.selectedStages);
      } else {
        this.filterAll = !this.filterAll;
        this.selectedStages = [];
        this.stages.forEach((stage) => {
          stage.selected = this.filterAll;
        });
        this.filterAllText = "All";
        console.log(this.selectedStages);
      }
    },
  },
  mounted() {
    this.$emit("getPickedStages", this.selectedStages);
  },
  watch: {
    "selectedStages.length": function () {
      if (
        this.selectedStages.length === this.$store.state.stages.length &&
        !this.filterAll
      ) {
        this.selectAllStages();
      } else if (
        this.selectedStages.length < this.$store.state.stages.length &&
        this.filterAll
      ) {
        this.filterAll = !this.filterAll;
        this.filterAllText = "All";
      }
      // this.filterAll
      //   ? (this.filterAllText = "Clear")
      //   : (this.filterAllText = "All");
      this.$emit("getPickedStages", this.selectedStages);
    },
  },
};
</script>

<style scoped>
.stages-wrapper {
  --stage-width: 50px;
  width: 100%;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--stage-width));
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray6);
  border-radius: 4px;
  padding: 1rem;
}
.stage,
.stage-selected {
  border-radius: 8px;
  width: var(--stage-width);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 250ms, background 250ms;
}
.filter-all {
  color: var(--info);
}
.stage:active,
.stage-selected:active {
  scale: 0.95;
}
.stage {
  background: var(--gray6);
}
.stage-selected {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px -1px;
}
.stage > p {
  color: var(--gray3) !important;
}
</style>